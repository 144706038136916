/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

// @import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
// @import "~@mdi/font/scss/materialdesignicons";
// @import "~flag-icon-css/sass/flag-icon";


/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";


/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";
@import url('https://fonts.googleapis.com/css?family=Muli');

//New font import start

@font-face {
    font-family: 'poppinslight';
    src: url('../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
        url('../assets/fonts/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsmedium';
    src: url('../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
        url('../assets/fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsregular';
    src: url('../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
        url('../assets/fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img {
    max-width: 100%;
    display: block;
} */

/* Natural flow and rhythm in articles by default */
article>*+* {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    // background-color: #1b3275 !important;
    transition: width 0.6s ease;
}


body {
    // background-color: #f7f8fc !important;
    // font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important;
    // font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 26px;
    color: #555555;
}

.menu_sub_icon {
    margin-left: auto;
}

.sub_menu_bg {
    background-color: #131e26;
}

@media (min-width:991px) {
    .collapse:not(.show) {
        display: inherit;
    }

    .accordian.collapse:not(.show) {
        display: none;
    }

    .side-menu.collapse:not(.show) {
        display: none;
    }
}

.RRT__tab {
    padding: 0 15px;
    padding: 0;
    // background-color: #fff !important;
    background-color: rgba(128, 128, 128, 0.075) !important;
    // color: #000 !important;
    position: relative;
    transition: all 0.5s ease;
    text-align: center;
    border: none !important;
    border-style: none !important;
    border-color: transparent !important;
    border-width: none !important;
    // padding: 5px 10px 5px 0 !important;
    // padding: 2px 10px 2px 0 !important;
    padding: 2px 10px 1px !important;
    margin-left: 17px !important;
    color: #58666e !important;

    @media (max-width:767px) {
        width: 100%;
        float: left;
        text-align: center;
        background-color: #ccc !important;
        color: #000;
        position: relative;
        transition: all 0.5s ease;

        &::before {
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: rgba(0, 0, 0, 0.9) !important;
            position: absolute !important;
            top: 13px !important;
            right: 13px !important;
            transform: rotate(38deg);
            transition: all 0.5s ease;
        }

        &::after {
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: rgba(0, 0, 0, 0.9) !important;
            position: absolute !important;
            top: 13px !important;
            right: 21px !important;
            transform: rotate(-38deg);
            transition: all 0.5s ease;
        }
    }
}


// .RRT__tab--selected , .RRT__tab:hover{
//     background: #fff !important;
//     // color: #5a5243 !important;
//     color: rgb(88, 147, 241) !important;
//     border-radius: 2px 2px 0 0 !important;
//     margin-bottom: 0 !important;
//     padding-left: 6px !important;
//     border-color: #fff !important;
//     // border-bottom: 2px solid #8278c2 !important;
//     border-left: 4px solid rgb(88, 147, 241) !important;
//     @media (max-width:767px){
//         background-color: #7265ba !important;
//         color: #fff !important;
//         transition: all 0.5s ease;
//         border: none;
//         &::before{
//             content: "";
//             width: 1px !important;
//             height: 13px !important;
//             background: #fff !important;
//             position: absolute !important;
//             top: 13px !important;
//             right: 13px !important;
//             transform: rotate(140deg);
//             transition: all 0.5s ease;
//         }
//         &::after{
//             content: "";
//             width: 1px !important;
//             height: 13px !important;
//             background: #fff !important;
//             background-color: #fff;
//             position: absolute!important; 
//             top: 13px !important;
//             right: 21px !important;
//             transform: rotate(-140deg);
//             transition: all 0.5s ease;
//         }
//     }
// }



.RRT__tab--selected {
    background: #fff !important;
    // color: #5a5243 !important;
    color: rgb(88, 147, 241) !important;
    border-radius: 2px 2px 0 0 !important;
    margin-bottom: 0 !important;
    padding-left: 6px !important;
    border-color: #fff !important;
    // border-bottom: 2px solid #8278c2 !important;
    border-left: 3.5px solid rgb(88, 147, 241) !important;

    @media (max-width:767px) {
        background-color: #7265ba !important;
        color: #fff !important;
        transition: all 0.5s ease;
        border: none;

        &::before {
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: #fff !important;
            position: absolute !important;
            top: 13px !important;
            right: 13px !important;
            transform: rotate(140deg);
            transition: all 0.5s ease;
        }

        &::after {
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: #fff !important;
            background-color: #fff;
            position: absolute !important;
            top: 13px !important;
            right: 21px !important;
            transform: rotate(-140deg);
            transition: all 0.5s ease;
        }
    }
}


.RRT__tab:hover {
    // background: #fff !important;
    border-radius: 2px 2px 0 0 !important;
    margin-bottom: 0 !important;
    // padding-left: 6px !important;
    // border-color: #fff !important;
    border-color: #5db1f9 !important;

    // border-left: 4px solid rgb(88, 147, 241) !important;
    @media (max-width:767px) {
        background-color: #7265ba !important;
        color: #fff !important;
        transition: all 0.5s ease;
        border: none;

        &::before {
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: #fff !important;
            position: absolute !important;
            top: 13px !important;
            right: 13px !important;
            transform: rotate(140deg);
            transition: all 0.5s ease;
        }

        &::after {
            content: "";
            width: 1px !important;
            height: 13px !important;
            background: #fff !important;
            background-color: #fff;
            position: absolute !important;
            top: 13px !important;
            right: 21px !important;
            transform: rotate(-140deg);
            transition: all 0.5s ease;
        }
    }
}



// .RRT__accordion{
//     @media (min-width:767px){
//         flex-direction : row !important;
//     }
// }
.nav-tabs .nav-link {
    color: #a2a2a2;
}

.mdb-dataTable-head>tr>th.sorting::before,
.mdb-dataTable-head>tr>th.sorting_asc::before,
.mdb-dataTable-head>tr>th.sorting_desc::before {
    content: "\2191" !important;
    font-weight: 1000 !important;
}

.mdb-dataTable-head>tr>th.sorting::after,
.mdb-dataTable-head>tr>th.sorting_asc::after,
.mdb-dataTable-head>tr>th.sorting_desc::after {
    font-weight: 1000 !important;
    content: "\2193" !important;
}

.rdt_TableBody>div:hover {
    box-shadow: 0px 0px 14px #c7c0c082;
    -webkit-box-shadow: 0px 0px 14px #c7c0c082;
    background-color: #fff !important;
    border-bottom-color: #e0e0e0 !important;
}

.multiselect-container {
    .optionListContainer>.optionContainer>li:hover {
        background: rgb(240, 238, 238);
        color: #333;
        cursor: pointer;
    }
}

.FundTransferModal>.modal-content {
    height: 100%;
    border-radius: 0;
    background: #fff;
    padding: 20px 35px 80px;
    overflow-y: scroll;
}

.rdt_TableCell {
    div {
        &:first-child {
            white-space: normal !important;
        }
    }
}

.filepond--panel-root {
    background: none !important;
}

.filepond--root {
    width: 170px;
    margin: 0 auto;
}

.react-datepicker-wrapper {
    display: block;
}

.react-datepicker-wrapper {
    display: block !important;
}

.a:focus,
input:focus {
    border: 1px solid #cfdadd;
    ;
}

.modalBackDrops {
    background-color: #3a3f51;

    &.show {
        opacity: 0.8;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}



.accordion .faq-collapse.card-header::before {
    font-family: 'FontAwesome';
    content: "\f053";
    float: right;
}


.accordion .faq-collapsed.card-header.collapsed::before {
    /* symbol for "collapsed" panels */
    content: "\f107";
}

.mtp30 {
    margin-top: 30px;
}

.form-control-xs {
    height: 2.2rem;
    padding: 0.5rem 0.81rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.modal-content {
    border: none;
}

.dropdown-toggle {
    background-color: transparent !important;
}

.iiHmzE {
    font-size: 15px !important;

}

.jsAMOb {
    margin-top: 21px !important;

}

.graph_main_container {
    width: 100%;
    height: auto;
    display: inline-block;
    margin-top: 30px;
    padding: 1rem !important;
}

.graph_box_crm {
    width: 100%;
    height: auto;
    padding: 15px;
    box-shadow: 0px 0px 20px #e5e5e5;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
}

.msg_box_cnt_t {
    width: 73%;
    float: right;
}

.sidebar-icon-only .content-wrapper {
    padding-left: 20px;
}

.CalendarContainer-sc-1gorc7r-0 {
    z-index: 9;
}

.hHSTiF {
    overflow: auto !important;
    height: 450px;
}

.table-responsive {
    background-color: #fff;
    padding: 10px;
}

.sidebar-icon-only .commonstyle_main_contant_sec__106Wq .commonstyle_topbar_section__1lK-R .commonstyle_logo_sec__5Gx-V .commonstyle_menubartogglebtn__i-OwH span {
    background-color: transparent !important;
}

.sidebar-icon-only .commonstyle_main_contant_sec__106Wq .commonstyle_topbar_section__1lK-R .commonstyle_logo_sec__5Gx-V .commonstyle_menubartogglebtn__i-OwH span::before {
    transform: rotate(140deg);
    bottom: 20px;
    width: 25px;
}

.sidebar-icon-only .commonstyle_main_contant_sec__106Wq .commonstyle_topbar_section__1lK-R .commonstyle_logo_sec__5Gx-V .commonstyle_menubartogglebtn__i-OwH span::after {
    bottom: 20px;
    width: 25px;
    transform: rotate(46deg);
}

.btn-primary {
    background-color: #7266ba !important;
    border-color: #7266ba !important;
}

.btn-primary:focus {
    background-color: #7266ba !important;
    border-color: #7266ba !important;
}

.btn-primary:hover {
    background-color: #7266ba !important;
    border-color: #7266ba !important;
}

.btn-info {
    background-color: #7266ba !important;
    border-color: #7266ba !important;
}

.dropdown-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
}

.ilLGeD {
    font-size: 15px !important;
    font-family: 'poppinsregular';
}

.table th,
.table td {
    font-size: 15px !important;
    font-family: 'poppinsregular';
}

p {
    font-size: 15px !important;
    font-family: 'poppinsregular';
}

.fjxduL {
    font-size: 15px !important;
    font-family: 'poppinsregular';
}

.cart_btnAddonInfo__1EvR0:hover {
    background: #19a9d5 !important;
    border-color: #19a9d5 !important;
}

.theme_changer_view {
    width: 175px;
    height: 40px;
    position: fixed;
    right: -130px;
    top: 20%;
    background-color: #fff;
    border-radius: 30px;
    z-index: 99;
    box-shadow: 0px 7px 28px #ccc;
    transition: 0.2s ease;
}

.theme_changer_view:hover {
    right: -20px;
}

.themechange_view_setting {
    width: 50px;
    height: 40px;
    text-align: center;
    float: left;
    color: #242424;
    font-size: 20px;
    line-height: 42px;
}

.themechange_view_area {
    width: 100px;
    float: left;
}

.themechange_view_boxs {
    width: 30px;
    height: 30px;
    float: left;
    background-color: #E7F2F7;
    margin-left: 3px;
    border-radius: 50px;
    margin-top: 5px;
    cursor: pointer;
}

.themechange_view_boxs1 {
    width: 30px;
    height: 30px;
    float: left;
    background-color: #E7F2F7;
    margin-left: 3px;
    border-radius: 50px;
    margin-top: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.themechange_box1::after {
    width: 50%;
    height: 30px;
    background-color: #616976;
    content: '';
    position: absolute;
    left: 0;
    z-index: 99;
}

.themechange_view_boxs2 {
    width: 30px;
    height: 30px;
    float: left;
    background-color: #616976;
    margin-left: 3px;
    border-radius: 50px;
    margin-top: 5px;
    cursor: pointer;
}



.Common_currContainer__nbt09 {
    display: block !important;
}

body iframe {
    display: none;
}

/****************  Start Left style theme  *****************/
.left-side-style .sidebar {
    background-color: #4F5359 !important;
    margin-top: 0;
    padding-top: 60px;
}

.left-side-style .sidebar>.nav:not(.sub-menu)>.nav-item>.nav-link {
    color: #bdbdbd;
}

.left-side-style .sidebar>.nav .nav-item .nav-link .menu-title {
    color: #bdbdbd !important;
}

.left-side-style .sidebar>.nav .nav-item .nav-link .menu-title:hover {
    color: #fff !important;
}

.left-side-style .sidebar>.nav .nav-item .nav-link:hover .menu-title {
    color: #fff !important;
}

.left-side-style .sidebar>.nav .nav-item .sub-menu .nav-item .nav-link {
    color: #bdbdbd;
}

.left-side-style .sidebar>.nav .nav-item.active>.nav-link .menu-title {
    color: #fff;
}

.left-side-style .sidebar>.nav .nav-item .sub-menu .nav-item .nav-link:hover {
    color: #fff;
}

// .left-side-style .sidebar::before{background-color: #4F5359 !important;content: '';position: absolute;left: 0;top: -70px; width: 300px; height: 100%;z-index: -1;}
.left-side-style .main-panel {
    z-index: 0;
    background-color: #e8f9ff;
    border-radius: 50px 0 0 0;
    height: 100%;
}

.left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK {
    background-color: transparent !important;
    box-shadow: none !important;
}

.left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_menubartogglebtn__3a9vw span {
    background-color: #fff;
}

.left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_menubartogglebtn__3a9vw span::before {
    background-color: #fff;
}

.left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_menubartogglebtn__3a9vw span::after {
    background-color: #fff;
}

.left-side-style.dashboard_main_contant_sec__27uZK {
    background-color: #4F5359 !important;
}

.left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK::before {
    width: 100%;
    height: 100%;
    content: '';
    background-color: #e8f9ff;
    left: 200px;
    top: 0;
    z-index: -2;
    position: absolute;
    border-radius: 50px 0;
}

.sidebar-icon-only .left-side-style .main-panel {
    width: auto !important
}

.sidebar-icon-only .left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK::before {
    left: 80px;
}

.sidebar-icon-only .left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_logo_cn__1tFeb {
    margin-left: 30px;
}

.sidebar-icon-only .left-side-style .main-panel {
    left: 80px;
}

.sidebar-icon-only .left-side-style .sidebar>.nav .nav-item .nav-link:hover .menu-title {
    color: #505050 !important;
    left: 72px
}

.sidebar-icon-only .left-side-style .sidebar .nav.sub-menu {
    margin-top: 0 !important;
}

.sidebar-icon-only .left-side-style .sidebar>.nav .nav-item .sub-menu .nav-item .nav-link {
    color: #242424 !important;
}

@media (max-width: 768px) {
    .left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK:before {
        border-radius: 0 !important;
        left: 0 !important
    }

    .left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_menubartogglebtn__3a9vw span {
        background-color: #242424;
    }

    .left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_menubartogglebtn__3a9vw span::before {
        background-color: #242424;
    }

    .left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_menubartogglebtn__3a9vw span::after {
        background-color: #242424;
    }

    .left-side-style .sidebar {
        margin-top: 70px;
        padding-top: 0;
    }

    .sidebar-icon-only .left-side-style .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_logo_cn__1tFeb {
        margin-left: 0 !important;
    }

    .sidebar-icon-only .left-side-style .sidebar>.nav .nav-item .sub-menu .nav-item .nav-link {
        color: #ffffff !important;
    }
}

/****************  Left style theme end   *****************/


/****************  Start Dark theme  *****************/
.theme-dark {
    background-color: #4F5359 !important;
    background-image: none !important;
    color: #c1b9b9 !important;
}

.theme-dark .content-wrapper {
    background: #616976;
}


.theme-dark .sidebar {
    background-color: #4F5359 !important;
    margin-top: 0;
    padding-top: 60px;
}

.theme-dark .sidebar>.nav:not(.sub-menu)>.nav-item>.nav-link {
    color: #bdbdbd;
}

.theme-dark .sidebar>.nav .nav-item .nav-link .menu-title {
    color: #fff !important;
}

.theme-dark .sidebar>.nav .nav-item .nav-link .menu-title:hover {
    color: #fff;
}
.theme-dark.sidebar-icon-only .sidebar>.nav .nav-item .nav-link .menu-title {
    color: #fff !important;
    background-color: #040c18 !important;

}
.theme-dark .sidebar>.nav .nav-item .nav-link{
    color: #fff !important;
}
.theme-dark .sidebar>.nav .nav-item .nav-link:hover .menu-title{color: #19a9d5 !important;}

.theme-dark.sidebar-icon-only .nav .nav-item .sub-menu .nav-item .nav-link {
    color: #fff !important;
    background-color: #040c18 !important;
}
.theme-dark.sidebar-icon-only  .sub_menu_bg{
    background-color: #040c18 !important;
    left: 87px !important;
    width: 186px !important;
}
.theme-dark.sidebar-icon-only  .sub_menu_bg .nav-link{
    color: #fff !important;
}

.theme-dark .sidebar>.nav .nav-item.active>.nav-link .menu-title {
    color: #fff !important;
}

.theme-dark .sidebar>.nav .nav-item .sub-menu .nav-item .nav-link:hover {
    color: #19a9d5 !important;
}

// .theme-dark .sidebar::before{background-color: #4F5359 !important;content: '';position: absolute;left: 0;top: -70px; width: 300px; height: 100%;z-index: -1;}
.theme-dark .main-panel {
    z-index: 0;
    background-color: #616976;
    border-radius: 50px 0 0 0;
    height: 100%;
}

.theme-dark .commonstyle_main_contant_sec .commonstyle_topbar_section {
    background-color: transparent !important;
    box-shadow: none !important;
}

.theme-dark .commonstyle_main_contant_sec .commonstyle_topbar_section .commonstyle_logo_sec .commonstyle_menubartogglebtn span {
    background-color: #fff !important;
}

.theme-dark .commonstyle_menubartogglebtn__i-OwH span .commonstyle_menubartogglebtn__i-OwH span::before .commonstyle_menubartogglebtn__i-OwH span::after{
    background-color: #fff !important;
}

.theme-dark .commonstyle_topbar_section__1lK-R {
    background-color: #4F5359   !important;
}

.theme-dark .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_menubartogglebtn__3a9vw span::before {
    background-color: #fff !important;
}

.theme-dark .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_menubartogglebtn__3a9vw span::after {
    background-color: #fff !important;
}

.theme-dark.dashboard_main_contant_sec__27uZK {
    background-color: #4F5359 !important;
}

.theme-dark .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK::before {
    width: 100%;
    height: 100%;
    content: '';
    background-color: #4F5359  ;
    left: 200px;
    top: 0;
    z-index: -2;
    position: absolute;
    border-radius: 50px 0;
}

.sidebar-icon-only .theme-dark .main-panel {
    width: auto !important
}

.sidebar-icon-only .theme-dark .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK::before {
    left: 80px;
}

.sidebar-icon-only .theme-dark .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK .commonstyle_logo_sec__5GhOB .commonstyle_logo_cn__1tFeb {
    margin-left: 30px;
}

.sidebar-icon-only .theme-dark .main-panel {
    left: 80px;
}

.sidebar-icon-only .theme-dark .sidebar>.nav .nav-item .nav-link:hover .menu-title {
    color: #505050 !important;
    left: 72px
}

.sidebar-icon-only .theme-dark .sidebar .nav.sub-menu {
    margin-top: 0 !important;
}

.sidebar-icon-only .theme-dark .sidebar>.nav .nav-item .sub-menu .nav-item .nav-link {
    color: #242424 !important;
}

.dashboard_main_contant_sec__27uZK .dashboard_contant_sec__2_JAc .dashboard_cntr_main_cnt_sc__30EU0 .dashboard_dashboard_main_cnt__22RCd .dashboard_dashboard_pannel_box__-YH-v {
    background: #ffffff14;
}

.theme-dark .dashboard_main_contant_sec__27uZK .dashboard_contant_sec__2_JAc .dashboard_cntr_main_cnt_sc__30EU0 .dashboard_dashboard_main_cnt__22RCd .dashboard_dashboard_pannel_box__-YH-v {
    background: #ffffff0f;
}

.theme-dark .dashboard_profile_main_txt_sc__33q9E strong {
    color: #fff !important;
}

.theme-dark .main_contant_sec .contant_sec .cntr_main_cnt_sc .dashboard_main_cnt .dashboard_pannel_box .profile_main_sec .profile_main_txt_sc span {
    color: #fff;
}

.theme-dark .themechange_setting_icon {
    box-shadow: 0px 7px 28px #060606;
    background-color: #204068;
}

.theme-dark .dashboard_profile_main_txt_sc__33q9E span {
    color: #cfcfcf;
}

.theme-dark .dashboard_profilesoc_share_bx_hd__1vqoz {
    color: #d5d5d5 !important;
}

.theme-dark .dashboard_view_prfl_btn__3dSK2 {
    color: #fff !important;
}

.theme-dark .dashboard_profile_other_box_cnt__3SUqw strong {
    color: #fff !important;
}

.theme-dark .dashboard_profile_other_box_cnt__3SUqw span {
    color: #fff !important;
}

.theme-dark .dropdown-toggle {
    color: #fff !important;
}

.theme-dark .dashboard_profile_main_sec__1qkby {
    border-right: 1px #e5e5e52e solid !important;
    ;
}

.theme-dark .dashboard_profile_soc_share_sec__4otCX {
    border-right: 1px #e5e5e52e solid !important;
    ;
}

.theme-dark .dashboard_profile_other_box__2uIkO {
    border-right: 1px #e5e5e52e solid !important;
    ;
}

.theme-dark .theme_changer_view {
    box-shadow: 0px 7px 28px #060606;
    background-color: #204068;
}

.theme-dark .themechange_view_setting {
    color: #fff;
}

.theme-dark .dashboard_listing_box_head__11AT0 {
    color: #fff !important;
}

.theme-dark .dashboard_listing_box_sec__2ZO03:after {
    opacity: 0.1 !important
}

.theme-dark .dashboard_lst_box_quick_select_btn__1s9TW i {
    color: #fff !important;
}

.theme-dark .dashboard_sponcer_top_name_txt__1Tcs7 {
    color: #fff !important;
}

.theme-dark .dashboard_spouncer_cnt_boxes__yGKqQ {
    color: #c9c9c9 !important;
}

.theme-dark .dashboard_spouncer_cnt_boxes__yGKqQ strong {
    color: #fff !important;
}

.theme-dark .dashboard_sponcer_top_ico__8-Bfr img {
    mix-blend-mode: multiply;
}

.theme-dark .dashboard_dashboard_newsec_grph_sec__200qG #reactgooglegraph-1 rect {
    fill: transparent;
}

.theme-dark .dashboard_listing_box_ico_rnd__1BTCH::after {
    opacity: 0.2 !important;
}

.theme-dark .dashboard_dashboard_new_member_head__2pFQc {
    color: #fff !important;
}

.theme-dark .dashboard_dashboard_new_member_name__3fYc0 {
    color: #fff !important;
}

.theme-dark .dashboard_dashboard_new_member_id_date___BTd_ {
    color: #c9c9c9 !important;
}

.theme-dark .dashboard_dashboard_new_member_date_sc__30iyv {
    color: #d9d7d7 !important;
}

.theme-dark .dashboard_dashboard_new_member_amnt__hLiFG {
    color: #d9d7d7 !important;
}

.theme-dark .dashboard_dashboard_new_member_row__zcGvS {
    border-bottom: 1px #e5e5e514 solid !important;
}

.theme-dark .dashboard_dashboard_newsec_head__1gIco {
    color: #fff !important;
}

.theme-dark .dashboard_dashboard_rank_row__TBExZ {
    border-bottom: 1px #e5e5e51f solid !important;
    color: #fff !important;
}

.theme-dark .dashboard_dashboard_rank_hd__3iWca {
    color: #b9b9b9 !important;
}

.theme-dark .dashboard_dashboard_rank_row_head__1Ccrr span {
    color: #8eaffd !important;
}

.theme-dark .dashboard_dashboard_rank_prg_bar_cnt__16NQM {
    color: #f1f1f1 !important;
}

.theme-dark .dashboard_dashboard_rank_box__1OrGB span {
    color: #ffffff !important;
}

.theme-dark .earn_n_team_content {
    background: transparent !important;
}

.theme-dark .dashboard_earning_tabs_name__1DMVJ {
    color: #c7c7c7 !important;
}

.theme-dark .earn_n_team_content div div {
    border-color: #e5e5e51f !important;
}

.theme-dark .dashboard_dashboard_newsec_grph_sec__200qG #reactgooglegraph-1 text {
    fill: #fff;
}

.theme-dark .dashboard_earning_tabs_name__1DMVJ {
    color: #d7d7d7 !important;
}

.theme-dark .earn_n_team_tabs {
    color: #bdbdbd !important;
}

.theme-dark .dashboard_dash_perfomencers_name__z-na0 {
    color: #dfdfdf !important;
}

.theme-dark .dashboard_dash_perfomencers_amnt__3Slok {
    color: #dfdfdf !important;
}

.theme-dark .earn_n_team_active-tabs {
    background: #ffffff2e !important;
}

.theme-dark .dashboard_earning_tabs_name__1DMVJ span {
    color: #ccd5cf !important;
}

.theme-dark .network_MianContent__2fplH .network_search__3XGmw {
    background: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid #ececec14 !important;
}

.theme-dark .Common_page_title_text__19ocE {
    color: #f1f1f1 !important;
}

.theme-dark input,
.theme-dark .form-control:focus,
.theme-dark input:focus,
.theme-dark select:focus,
.theme-dark textarea:focus,
.theme-dark button:focus {
    border: 1px solid #cfdadd0f !important;
    background: #4F5359   !important;
    color: #fff !important;
}

.theme-dark .sidebar>.nav .nav-item.active>.nav-link .menu-icon::before {
    background: transparent !important;
}

.theme-dark .tree_figure__1i0f_ {
    background: #f6f6f614 !important;
    ;
    box-shadow: none !important;
    ;
}

.theme-dark .tree_username__ulK3H {
    color: #fff !important;
}

.theme-dark .tree_fullname__1sDkg {
    color: #979a9d !important;
}

.theme-dark .tree_tooltipInfo__24vCY {
    box-shadow: none !important;
    background: #f6f6f60d !important;
}

.theme-dark .tree_TreeViewContainer__MjxWQ .tree_treeview__294rV .tree_listGroup__iO_9A .tree_listGroupItem__uDq52 {
    border-color: #f0f3f400 !important;
}

.theme-dark .tree_TreeViewContainer__MjxWQ legend {
    color: #979797 !important;
    border-bottom: 1px solid #e5e5e50d !important;
}

.theme-dark .card {
    background-color: #ffffff12 !important;
}

.theme-dark .form-group label {
    color: #fff !important;
}

.theme-dark .table-responsive {
    background-color: transparent !important
}

.theme-dark .table td {
    color: #fff !important;
    border-top: 1px solid #dee2e617 !important;
}

.theme-dark .table thead th {
    color: #fff !important;
    border-bottom: 2px solid #dee2e62e !important;
}

.theme-dark select.form-control {
    color: #696868 !important;
    background: #ffffff1a !important;
    outline: 1px solid #dee2e629 !important;
    color: #fff !important;
}

.theme-dark .dropdown-toggle:focus {
    background-color: transparent !important;
    border: 0 !important;
}

.theme-dark .registration_SponsorPackage__1XXkW {
    background: #ffffff0f !important;
}

.theme-dark .registration_heading__R65US {
    color: #fff !important;
}

.theme-dark .ewallet_Tiles__1UrLr {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5) !important;
    border-radius: 20px !important;
    background-color: #ffffff0f !important;
}

.theme-dark .ewallet_tilesData__3OQwL h4 {
    color: #fff !important;
}

.theme-dark .ewallet_tilesData__3OQwL span {
    color: #fff !important;
}

.theme-dark .ewallet_tabContent__1GxpI {
    background: #ffffff0d !important;
    border-radius: 20px !important;
}

.theme-dark .kgEPYv {
    color: #fff !important;
    background-color: transparent !important;
}

.theme-dark .rdt_TableHeadRow {
    color: #fff !important;
    background-color: transparent !important;
}

.theme-dark .rdt_TableHead {
    color: #fff !important;
    background-color: transparent !important;
}

.theme-dark .rdt_TableBody {
    color: #fff !important;
    background-color: transparent !important;
}

.theme-dark .rdt_TableRow {
    color: #fff !important;
    background-color: transparent !important;
}

.theme-dark .rdt_Pagination {
    color: #fff !important;
    background-color: transparent !important;
}

.theme-dark .rdt_Table div {
    color: #fff !important;
    background-color: transparent !important;
}

.theme-dark .rdt_TableBody>div:hover {
    color: #fff;
    background-color: transparent !important;
    outline-color: #ffffff1c !important;
    box-shadow: none !important;
    border-bottom-color: #e0e0e014 !important;
}

.theme-dark .rdt_Table {
    background-color: transparent !important;
}

.theme-dark .ewallet_badge__3NU0e.ewallet_credit__1doxV {
    background-color: #dbf2e829 !important;
}

.theme-dark .jxflYm {
    color: rgba(199, 199, 199, 0.7) !important;
    border-top-color: rgba(0, 0, 0, .12) !important;
    background-color: #dbf2e808 !important;
}

.theme-dark .bvxQGL {
    color: rgba(255, 255, 255, 0.7) !important;
    fill: rgba(255, 255, 255, 0.4) !important;
}

.theme-dark .RRT__tab--selected {
    background: #ffffff17 !important;
}

.theme-dark .ewallet_DateRangePicker__VSZ5R {
    border: 1px solid #cccccc1f !important;
    color: #b3b3b3 !important;
    background: #ffffff05 !important;
}

.theme-dark ._2iA8p44d0WZ-WqRBGcAuEV {
    border: 1px solid #cccccc1f !important;
}

.theme-dark .fwKvpK {
    color: rgba(181, 181, 181, 1) !important;
    background-color: #ffffff12 !important;
}

.theme-dark .payout_Tiles__3WwHS {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5) !important;
    border-radius: 20px !important;
    background-color: #ffffff0f !important;
}

.theme-dark .payout_Tiles__3WwHS h4 {
    color: #fff !important;
}

.theme-dark .payout_Tiles__3WwHS span {
    color: #fff !important;
}

.theme-dark .payout_tabContent__1OGZj {
    background: #ffffff0d !important;
    border-radius: 20px !important;
}

.theme-dark .nav-tabs {
    border-bottom: 1px solid #ebedf22b;
}

.theme-dark .payout_MainContainer__1IUcx .payout_summary__1xlwI .payout_tabContent__1OGZj .payout_dataTable__1yCaa .payout_filterDropDown__257OR {
    border: 1px solid #cfdadd40 !important;
    color: #fff;
}

.theme-dark .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border-bottom: 0;
    color: #f7f7f7;
    background-color: #ffffff0d;
    border-color: #cccccc1f;
}

.theme-dark .epin_Tiles__3XV7j {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5) !important;
    border-radius: 20px !important;
    background-color: #ffffff0f !important;
}

.theme-dark .epin_Tiles__3XV7j h4 {
    color: #fff !important;
}

.theme-dark .epin_Tiles__3XV7j span {
    color: #fff !important;
}

.theme-dark .epin_tabContent__zYNKK {
    background: #ffffff0d !important;
    border-radius: 20px !important;
}

.theme-dark ._3vt7_Mh4hRCFbp__dFqBCI input {
    border: none !important;
    background: transparent !important;
    color: #fff !important;
}

.theme-dark .epin_Maincontainer__2eZlO .epin_summary__3s1ui .epin_tabContent__zYNKK .epin_dataTable__1ymuQ .epin_filterSection__3FgVg .epin_filterDropDown__3wwli {
    border: 1px solid #cfdadd40 !important;
    color: #fff;
}

.theme-dark .epin_badge__21nlp.epin_badgegreen__YoO1I {
    background-color: #dbf2e824 !important;
}

.theme-dark .epin_refundText__1wtMT {
    background-color: transparent !important;
}

.theme-dark .epin_badge__21nlp.epin_badgeblue__3gKmA {
    color: #bbb0ff !important;
}

.theme-dark .cart_container__35Pln .cart_wrapper__vOzu0 {
    background: transparent !important;
    border: 0 !important;
}

.theme-dark .card-footer {
    border-top: 1px solid #e6e6e617;
}

.theme-dark .cart_container__35Pln .cart_itemTitle__2oMOM {
    color: #fff !important;
}

.theme-dark .earn_n_team_activee-tabs {
    background: #ffffff1f;
}

.theme-dark .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e626;
}

.theme-dark .table-bordered {
    border: 1px solid #dee2e633;
    background: #4F5359;
}

.theme-dark .Common_addonNotification__1LSin .Common_addonDescription__UYwmT div {
    color: #ffffffab;
}

.theme-dark .wizard_formBox__1sEWE .wizard_formWizard__20VyO {
    background: #fff0 !important;
}

.theme-dark .cart_checkoutContainer__2U2Bp legend {
    color: #f5f5f5 !important;
    border-bottom: 1px solid #e5e5e51a !important;
}

.theme-dark .wizard_step__1kwtO {
    border: 2px solid #ffffff14 !important;
    background: #cccccc0f !important;
}

.theme-dark .wizard_step__1kwtO.wizard_active__3C-DM {
    background: #7266ba !important;
}

.theme-dark .Common_DateRangePicker__3_xnw {
    background: #ffffff14 !important;
    border: 1px solid #cccccc12 !important;
    color: #fff !important;
}

.theme-dark .mail_mailboxMainContent__308Wv .mail_leftPanel__2D0FC {
    background-color: #e4eaec0d !important;
    border-right: 1px solid #d4d6d626 !important;
    border-bottom: 1px solid #f7f7f71c
}

.theme-dark .mail_mailboxMainContent__308Wv .mail_leftPanel__2D0FC::before {
    background-color: #edf1f214 !important;
}

.theme-dark .mail_mailboxMainContent__308Wv .mail_rightSide__1bnNT .mail_headerContent__2diup {
    background-color: #f6f8f826 !important;
    border-bottom: 1px solid #dee5e7 !important;
    border-bottom: 1px solid #dee5e738 !important
}

.theme-dark .mail_mailboxMainContent__308Wv .mail_rightSide__1bnNT .mail_InboxList__YhfHp .mail_listItems__2CXTj {
    border-left-color: #23b7e526 !important;
    background-color: #ffffff0d !important;
}

.theme-dark .mail_mailDetails__pPaVg {
    color: #fff !important;
}

.theme-dark .mail_dateActions__2VYIb {
    color: #58666e !important;
}

.theme-dark .mail_mailboxMainContent__308Wv .mail_rightSide__1bnNT .mail_headerContent__2diup .mail_btnGroup__3zHoW button {
    color: #fff !important;
}

.theme-dark .mail_mailboxMainContent__308Wv .mail_leftPanel__2D0FC .mail_wrapper__1i7_e {
    background-color: #27364a !important;
}

.theme-dark .jVeafb {
    color: #fff !important;
    background-color: #21344a !important;
}

.theme-dark .FundTransferModal>.modal-content {
    background: #031834 !important;
}

.theme-dark .modal-title.h4 {
    color: #fff;
}

.theme-dark .tools_replicaContent__1Rh0o h4 {
    color: #fff;
}

.theme-dark .form-label {
    color: #fff;
}

.theme-dark .tools_dropzoneWrapper__242AO .tools_dropzoneDesc__1vgDF {
    color: #dddddd;
}

.theme-dark .support_container__1TwZo .support_borderColumn__FcWsW {
    border: 1px solid #dddddd1a;
}

.theme-dark .accordion {
    background-color: transparent !important;
    color: #fff !important;
}

.theme-dark .package_detail_displayFlex__34E7S {
    color: #fff !important;
}

.theme-dark .profile_panel__1WP_v {
    background-color: #ffffff08 !important;
}

.theme-dark .profile_ProfileFullName__3kWy3 {
    color: #fff !important;
}

.theme-dark .profile_ProfileUserName__2Y1qb {
    color: #adadad !important;
}

.theme-dark .profile_UserEmail__pNpov {
    color: #adadad !important;
}

.theme-dark .profile_PasswordButton__3dBPk {
    background: #f9f9f912 !important;
    border: 1px solid #f4f4f41c !important;
    color: #cec5fd !important;
}

.theme-dark .profile_kyc__1tUo_ div {
    color: #b3b3b3 !important;
    border: 1px solid #8f8aa538 !important;
}

.theme-dark .profile_rankText__iscFF {
    color: #fff !important;
}

.theme-dark .profile_package__2jrp9 p {
    color: #fff !important;
}

.theme-dark .profile_sponsorPlacement__1PfYy div .profile_textheader__2UnM6 {
    color: #d7d7d7 !important;
}

.theme-dark .profile_sponsorPlacement__1PfYy div .profile_textvalue__3qyLw span {
    color: #fff !important;
}

.theme-dark .profile_UserDetails__31FnE {
    border: 1px solid #eeeeee1f !important;
}

.theme-dark .profile_PersonalDetails__3Z0qy.profile_verticalLine__eVo7n {
    border-right: 1px solid #dbdbdb12 !important;
}

.theme-dark .profile_userTitle__1BJPV {
    color: #d7d7d7 !important;
}

.theme-dark .profile_userHead__1JA_j {
    color: #fff !important;
}

.theme-dark .profile_MainContainer__TNXdm .profile_EditContent__1Zxvn .profile_PersonalDetials__3OuOH {
    background-color: #0a1b32;
}

.theme-dark .profile_ContactDetails__33BWK {
    background-color: #0a1b32 !important;
}

.theme-dark .profile_FormHead__1YWH8 .profile_headeText__2CbK8 {
    color: #fff !important;
}

.theme-dark .profile_MainContainer__TNXdm .profile_EditContent__1Zxvn .profile_BankDetials__2-RMU {
    background-color: #0a1b32 !important
}

.theme-dark .profile_MainContainer__TNXdm .profile_EditContent__1Zxvn .profile_PaymentDetails__3pIO_ {
    background-color: #0a1b32 !important
}

.theme-dark .profile_MainContainer__TNXdm .profile_EditContent__1Zxvn .profile_Settings__1ZxDI {
    background-color: #0a1b32 !important
}

.theme-dark legend {
    border-bottom: 1px solid #e5e5e526 !important;
    color: #efefef !important;
}

.theme-dark .tools_invitesEmailItem__2Rh5K .tools_inviteItemContent__2Ba-5 {
    background: #1c2f46 !important;
}

.theme-dark .tools_userName__L3Wrl {
    color: #c9d1d9 !important;
}

.theme-dark .tools_inviteItemContent__2Ba-5 span {
    color: #a0b1c1 !important;
}

.theme-dark .tools_inviteItemContent__2Ba-5 p {
    color: #c4ccd3 !important;
}

.theme-dark .tools_cardBody__3CU4z h4 {
    color: #c4ccd3 !important;
}

.theme-dark .Common_formsInputField__2gNbj {
    background-color: #e9ecef14 !important;
    color: #cbcbcb !important;
    border: 1px solid #cfdadd1a !important;
}

.theme-dark .tools_postTitle__2txKR {
    color: #cbcbcb !important
}

.theme-dark .tools_formControl__2De4t label {
    color: #cbcbcb !important
}

.theme-dark .tools_socialContent__3ZLTV {
    background-color: #e9ecef14 !important;
}

.theme-dark .tools_socialContent__3ZLTV h3 {
    color: #fff !important;
}

.theme-dark .tools_socialContent__3ZLTV span {
    color: #cbcbcb !important
}

.theme-dark .tools_socialContent__3ZLTV .tools_text__1c0sl p {
    color: #cbcbcb !important
}

.theme-dark .tools_downloadConatiner__2fPT1 .tools_userDowmloadSection__10hvb .tools_content__2dlsU {
    background-color: #e9ecef14 !important;
    color: #b9b9b9;
}

.theme-dark .tools_link__Htvn1 {
    color: #fff !important;
}

.theme-dark .tools_NewsDetails__3sMN1 .tools_title__1m6L_ {
    color: #fff !important;
}

.theme-dark .tools_NewsDetails__3sMN1 p {
    color: #cbcbcb !important
}

.theme-dark .tools_dateDetails__3Myz0 {
    color: #c1b6ff !important;
}

.theme-dark .tools_linkContent__f314O a {
    color: #cbcbcb !important
}

.theme-dark .tools_recentNewsList__2CNJ_.card {
    border-bottom: 1px solid #dee5e700 !important;
}

.theme-dark .mail_panelHeading__3jbqi.card-header {
    background-color: #f6f8f805 !important;
}

.theme-dark .mail_replyContent__1vRlQ {
    border: 1px solid #dee5e729 !important;
}

.theme-dark .mail_ViewMailContent__3eLZ7 {
    border-bottom: 1px solid #dee5e71f !important;
}

.theme-dark .mailbox-nav .nav-link {
    color: #afafaf !important;
}

.theme-dark .support_chatAttach__2qA02 p {
    color: #afafaf !important;
}

.theme-dark .support_container__1TwZo .support_tableChat__gp8Wh td {
    color: #afafaf !important
}

.theme-dark .support_messages__3WOCY {
    background: #d6d0ff1c !important;
}

.theme-dark .support_messages__3WOCY p {
    color: #fff !important;
}

.theme-dark .support_messages__3WOCY:after {
    background: #d6d0ff1c !important;
}

.theme-dark .profile_ModalContent__sL8-M {
    background-color: #222940 !important;
}

.theme-dark .profile_ProfileSection__nIOb7 {
    border-right: 1px solid #dbdbdb26 !important;
}

.theme-dark .package_tabSection__1IcVY .package_tabContainer__Kk4BR {
    background: #f6f6f60f !important;
    border: 1px solid #e9e9e917 !important;
}

.theme-dark .package_upgradeDetails__2_CE2 {
    border: 1px solid #cecece14 !important;
}

.theme-dark .package_upgradeDetails__2_CE2 div {
    border-left: 1px solid #4e4e4e !important;
}

.theme-dark .upgradeDetailsContainer a {
    color: #afafaf !important;
}

.theme-dark .upgradeDetailsContainer p {
    color: #afafaf !important;
}

.theme-dark .card .card-title a {
    color: #fff !important;
}

.theme-dark .daterangepicker {
    background-color: #4F5359;
    border: 1px solid #dddddd30;
}

.theme-dark .daterangepicker .calendar-table {
    border: 1px solid #ffffff12;
    background-color: #ffffff0d;
}

.theme-dark .daterangepicker td.off {
    background-color: #ffffff0d;
}

.theme-dark .daterangepicker .drp-buttons .btn {
    color: #afafaf !important;
}

.theme-dark .daterangepicker .drp-buttons {
    border-top: 1px solid #dddddd2e !important;
}

.theme-dark .daterangepicker.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid #dddddd3b;
}

.theme-dark .daterangepicker .calendar-table .next span,
.theme-dark .daterangepicker .calendar-table .prev span {
    border-color: #cbc8c8;
    color: #cbc8c8
}

.theme-dark .RRT__tab {
    color: #a2b8c5 !important;
}

// .theme-dark #login-form input {
//     background: #e8f0fe !important;
//     color: #002644 !important;
// }

.theme-dark .dashboard_dashboard_newsec_grph_sec__200qG svg rect {
    fill: transparent;
}

.theme-dark .dashboard_dashboard_newsec_grph_sec__200qG svg text {
    fill: #fff;
}

.theme-dark .profile_Button__2EMGA.profile_cancel__3_Oaa {
    border: 1px solid #dee5e726;
    color: #fff !important;
    background-color: #ffffff2e;
}

.theme-dark .commonstyle_notification_msg_box_cnt_row__3MQdH.dropdown-item {
    background-color: #1b2b40 !important;
    box-shadow: 0px 2px 15px rgb(44, 65, 94);
    border-color: #f0ffff30;
    color: #fff !important;
}

.theme-dark .commonstyle_notification_msg_box_cnt_row__3MQdH.dropdown-item p {
    color: #fff;
}

.theme-dark .commonstyle_notification_msg_box__3t154.dropdown-menu {
    border-color: #f0ffff30;
    background-color: #1b2b40 !important;
}

.theme-dark .dropdown .dropdown-menu {
    border-color: #f0ffff30;
    background-color: #1b2b40 !important;
    box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.5);
}

.theme-dark .dropdown .dropdown-menu a {
    color: #fff !important;
}

.theme-dark .dashboard_profilesoc_share_bx_ico__1wM7E {
    background: #344256 !important;
    color: #fff !important;
}

.theme-dark .dashboard_dashboard_rank_box_rnd__1Ox5H {
    background-color: #d9eff438 !important;
    color: #ffffff !important;
}

.theme-dark .tooltip-container {
    background-color: #2c3c4f !important;
    border: 1px solid #5a5858 !important;
}

.theme-dark .Common_tooltipListItem__BhQ2v {
    background-color: #fff0 !important;
    color: #fff;
}

.theme-dark .network_right__2frEm {
    border-color: #c8d5d84d !important;
}

.theme-dark .network_top__1h93Z {
    border-color: #c8d5d84d !important;
}

.theme-dark .network_left__3bqAa {
    border-color: #c8d5d84d !important;
}

.theme-dark .tree_count__ElhAC {
    background: #d3e0d436 !important;
    color: #fff;
}

.theme-dark .tree_textPurple__2JcJH {
    color: #fff !important;
}

.theme-dark .input-group-append .input-group-text,
.theme-dark .input-group-prepend .input-group-text {
    color: #ffffff;
    border-color: #4F5359  ;
    background-color: #152943;
}

.theme-dark .ewallet_ModalBodyHeader__IcL0B {
    color: #ffffff;
    top: -30px;
    position: relative;
}

.theme-dark .dropdown-menu {
    background-color: #d9eff438 !important;
    border-color: #4F5359  
}

.theme-dark .dropdown-menu a {
    color: #fff !important;
}

.theme-dark .dropdown-item:hover,
.theme-dark .dropdown-item:focus {
    background-color: #e9ecef36;
}

.theme-dark .modal-content .modal-body h3 {
    color: #fff !important;
}

.theme-dark .alert-info {
    color: #ffffff;
    background-color: #e7e0f93d;
    border-color: #ded3f629;
}

.theme-dark .ewallet_badge__3NU0e.ewallet_balanceAmount__BTqCM {
    color: #c5bbff;
}

.theme-dark .ewallet_badge__3NU0e.ewallet_credit__1doxV {
    color: #66ffbd;
}

.theme-dark .daterangepicker td.in-range {
    background-color: #ebf4f814;
    border-color: transparent;
    color: #fff;
}

.theme-dark .daterangepicker td.available:hover {
    background-color: #357ebd;
}

.theme-dark .daterangepicker .ranges li:hover {
    background-color: #357ebd;
    color: #242424;
}

.theme-dark .optionListContainer {
    background: #2d435e;
}

.theme-dark .optionListContainer li {
    color: #fff !important;
    background: #2d435e !important;
}

.theme-dark .notfount_backToDashboard__2r6fU {
    background: #1e7df2 !important;
}

.theme-dark .notfount_notFount__mxmDo .notfount_notFountContent__21CDl p {
    color: #fff;
}

.theme-dark .sc-cxabCf option {
    color: #fff;
}

.theme-dark .epin_badge__21nlp.epin_badgegreen__YoO1I {
    color: #72ffc3;
}

.theme-dark .cart_categoryTxt__2_98B {
    color: #ffffff;
    background-color: #edf1f233;
}

.theme-dark .cart_container__35Pln .cart_price__3FVcF {
    color: #c9c0ff;
}

.theme-dark .list-group-item {
    background-color: #4F5359;
    border: 1px solid #dbd7d721;
}

.theme-dark .cart_addressCard__-569d {
    border: 1px solid #dee5e730;
}

.theme-dark .card-header {
    border-bottom: 1px solid #e6e6e68f;
}

.theme-dark .modal-content {
    background-color: #192b40 !important;
}

.theme-dark .modal-footer {
    border-top: 1px solid #dee2e63d !important;
}

.theme-dark .Common_PaymentMethods__38Igg {
    border: 1px solid #cccccc26;
    background-color: #fff0;
}

.theme-dark .Common_PaymentMethods__38Igg .Common_tab__15C5X button {
    background-color: #ffffff0a;
    border: 1px solid #dddddd0d;
    color: #ffffff;
}

.theme-dark .Common_PaymentMethods__38Igg .Common_tab__15C5X {
    border: 1px solid #cccccc47;
}

.theme-dark .Common_PaymentMethods__38Igg .Common_tab__15C5X button:hover {
    background-color: #dddddd21;
}

.theme-dark .payment_MianContent__1j6lz .payment_epinContent__2NxYp {
    background-color: #ffffff1f;
}

.theme-dark .payment_MianContent__1j6lz .payment_epinContent__2NxYp .payment_epinList__3o-9o {
    background-color: #eeeeee1f;
}

.theme-dark .payment_MianContent__1j6lz .payment_bankDetails__22Grn {
    background-color: #ffffff0f;
    color: #fff;
}

.theme-dark .mail_Editor__i2t5O {
    background-color: #ffffff0f;
    border-color: #35485e;
}

.theme-dark .rdw-editor-toolbar {
    background: #ffffff26;
    border: 1px solid #f1f1f11f;
}

.theme-dark .rdw-option-wrapper {
    border: 1px solid #f1f1f100;
    background: #ffffff1c;
}

.theme-dark .rdw-option-wrapper img {
    filter: invert(100%);
}

.theme-dark .rdw-dropdown-wrapper {
    background: #ffffff1f !important;
    background-color: #ffffff1f !important;
    border: 1px solid #f1f1f126;
}

.theme-dark .rdw-dropdown-selectedtext {
    color: #fff !important;
}

.theme-dark .rdw-dropdown-optionwrapper {
    border: 1px solid #f1f1f126 !important;
    background: #28374b !important;
}

.theme-dark .rdw-dropdownoption-active {
    background: #28374b !important;
}

.theme-dark .rdw-dropdownoption-highlighted {
    background: #f1f1f129;
}

body .mail_mailboxMainContent__308Wv .mail_rightSide__1bnNT .mail_headerContent__2diup .mail_btnGroup__3zHoW button i {
    color: #fff !important;
}

.theme-dark .mail_floatRight__3bsIv {
    color: #ffffffa6;
}

.theme-dark .mail_mailMessage__M4PcM p {
    color: #fff;
}

.theme-dark .btn-secondary {
    color: #c0e0ff;
    background-color: #dde4eb47;
    border-color: #dde4eb47;
}

.theme-dark .alert-warning {
    color: #ffffff;
    background-color: #ffefcc3d;
    border-color: #ffe9b842;
}

.theme-dark .alert-warning span {
    color: #ffff !important;
}

.theme-dark .support_formControl__3hNwS {
    border: 1px solid #dee2e642;
    color: #ffffff;
    background-color: #ffffff1a;
}

.theme-dark .support_btnFile__3IbkU {
    background: #507dd4 !important;
    color: #ffffff !important;
}

.theme-dark .support_btnFile__3IbkU i {
    color: #ffffff !important;
}

.theme-dark .support_body__ttaH3 {
    background: #ffffff21 !important;
}

.theme-dark .support_body__ttaH3 p {
    color: #fff !important;
}

.theme-dark .support_demo_card__3HCkk {
    background-color: #263b54 !important;
    ;
}

.theme-dark .support_alertFaq__Eni7T {
    color: #ffffff;
    background-color: #fffdf414;
    border-color: #faebcc1a;
}


@media (max-width: 768px) {
    .theme-dark .commonstyle_main_contant_sec__1QHaq .commonstyle_topbar_section__3CMDK:before {
        border-radius: 0 !important;
        left: 0 !important
    }

    .theme-dark .sidebar {
        margin-top: 70px;
        padding-top: 0;
    }

    .profile_package__2jrp9 {
        background-color: transparent !important;
        border: 1px solid #eeeeee21 !important;
    }

    .profile_renew__3eVQn {
        background: #f9f9f90d !important;
        border: 1px solid #eeeeee12 !important;
    }

    .payout_MainContainer__1IUcx .payout_payoutTiles__29HGy {
        width: 100% !important;
    }

    .epin_Maincontainer__2eZlO .epin_epinTiles__1ov82 {
        width: 100% !important;
    }
}

/****************  End Dark theme  *****************/




@media only screen and (max-width: 600px) {
    .RRT__accordion .RRT__tab {
        margin-left: 0 !important;
    }
}

@media (max-width: 768px) {
    .commonstyle_top_notification_sec__DaQsZ .Common_langContainer__1G76E {
        display: none;
    }

    .col {
        width: 100%;
        flex-basis: 100%;
    }

    .card_body {
        padding: 0 !important;
    }

    .card_body .media {
        margin-left: 0;
    }

    .Common_formGroup__wB176 {
        margin: 10px 0 !important;
    }

    .FundTransferModal>.modal-content {
        padding: 5px;
    }

    .payout_ModalDialog__1rd5W {
        max-width: 90% !important;
        width: 90% !important;
    }

}

@media (max-width: 500px) {
    .mail_mailboxMainContent__1u7Zj .mail_rightSide__1d49D .mail_composeMailContent__3Hhip .mail_listItems__3ZOYD .mail_content__1VSFZ .mail_type__ZHsUx {
        display: inline-block !important;
    }

    .invoice_exportButton__2JLh8 button {
        margin: 2px !important;
        margin-top: 10px !important;
    }

    .payout_exportButton__3d0xT button {
        margin: 2px !important;
        margin-top: 10px !important;
        padding: 0 11px;
    }

    .ewallet_MainContainer__2rJlL .ewallet_summary__3f9eN .ewallet_tabContent__3KFsw .ewallet_dataTable__Ca4XA .ewallet_filterSection__8SPCW .ewallet_exportButton__3Z6Ae button {
        margin: 2px !important;
        margin-top: 10px !important;
        padding: 0 11px;
    }

    .card .card-body {
        padding: 10px;
    }

    .ewallet_MainContainer__2rJlL .ewallet_summary__3f9eN .ewallet_tabContent__3KFsw .ewallet_TabPanel__e7Cbx {
        padding: 0px;
    }

    .Common_currContainer__nbt09 .Common_selectedCurr__3DJcJ {
        left: 0 !important;
        padding-top: 15px !important;
    }

    .Common_langContainer__1vIe3 {
        min-width: auto !important;
        margin-left: -60px !important;
    }

    .Common_langContainer__1vIe3 {
        padding-left: 0 !important;
    }

    .Common_langContainer__1vIe3 {
        min-width: auto !important;
        margin-left: 0 !important;
        position: relative !important;
        right: 55px !important;
        width: 40px !important;
    }

    .Common_langContainer__1vIe3 .Common_selectedLang__YUR3q {
        left: 0;
    }

}

@media (max-width: 380px) {
    .commonstyle_tp_msg_btn__90d19 {
        display: none !important;
    }

    .commonstyle_top_notification_sec__6Hg0L {
        width: 60px !important;
    }
}


//Replica

body{margin: 0;padding: 0;font-family: 'poppinsregular';}
.top_bar_section_1{
  width: 100%;
  height: auto;
  float: left;
  background-color: #00a4ff; 
  padding:10px 0;
}
.main_header_sec{
  width: 100%;
 // height: auto;
  float: left;
  box-shadow: 0px 1px 18px rgb(0, 0, 0 / 10%);
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
}
.main_logo{
  width: 100%;
  height: auto;
  float: left;
}
.main_logo img{max-width: 300px;}
.login_btn{
  padding: 10px 30px !important;
  background-color: #00a4ff;
  color: #fff !important;
  font-size: 16px;
  border-radius: 40px;
  transition: 0.2s ease;
}
.login_btn:hover{background-color: #242424;}
.banner_section{
  width: 100%;
  float: left;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  text-align: center;

}
.top_bar_section{
  width: 100%;
  height: auto;
  float: left;
}

.top_cnt_sec{
  width: 100%;
  height: auto;
  float: left;
  display: flex;
  align-items: center;
  justify-content: end;
}
.top_cnt_sec_box{
  color: #fff;
  font-size: 14px;
  margin-left: 16px;
}
.navbar_expand_lg .navbar-nav .nav-link{
  padding-right: 20px;
  padding-left: 20px;
  text-transform: uppercase;
  color: #242424 !important;
}
.menubar_sec{margin-top: 10px;}
.banner_contant_sec{
  width: 100%;
  height: auto;
  float: left;
  margin-top: 60px;
}
.banner_contant_sec h3{color: #666;margin-bottom: 20px;display: inline-block;}
.banner_button{
  width: 150px;
  height: 50px;
  background-color: transparent;
  text-align: center;
  line-height: 50px;
  color: #fff;
  margin-top: 10px;
  border-radius: 30px;
  display: inline-block;
  text-decoration: none;
  border: 1px solid;
}
.banner_button:hover{background-color: #fff;color:#242424;text-decoration: none;cursor: pointer;}
.plan_cnt_sec{
  width: 100%;
  height: auto;
  float: left;
  padding:90px 0;
  background-image: url(../assets/images/dot-big-square.svg);
  background-repeat: no-repeat;
  background-position: 270px 112px;
  min-height: 400px;
}
.plan_cnt_sec_head{
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
  color: #242424;
  margin-bottom: 30px;
  font-family: 'poppinsmedium';
}
.plan_cnt_sec_head h2{
  font-size: 40px;
}

.plan_box_sec{
  width: 100%;
  height: auto;
  float: left;
  padding:40px 20px;
  border-radius: 10px;
  box-shadow: 10px 3px 20px 0 rgb(235, 233, 233);
  // box-shadow: 10px 3px 20px 0 rgb(210 ,210, 245 / 50%);
  background-color: #fff;
  margin-top: 10px;
}
.plan_box_head{
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
  color: #242424;
  font-size: 25px;
}
.plan_box_head h3{margin: auto; color: #242424;  font-size: 25px;}
.plan_box_cnt{
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
  font-size: 16px;
  color: #666;
  line-height: 28px;
  margin-top: 20px;
}
.plan_box_cnt p{
  font-size: 16px;
  color: #666;
}
.about_section{
  width: 100%;
  height: auto;
  float: left;
  padding: 80px 0;
  background-color: #f3f3f3;
}
.about_section_contant{
  width: 100%;
  height: auto;
  float: left;
}
.about_sectioncnt_sec_head{
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
  color: #242424;
  font-size: 18px;
}
.about_sectioncnt_sec_head h2{
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
  color: #242424;
  font-family: 'poppinsmedium';
  font-size: 40px;
}
.about_section_contant_head{
  width: 100%;
  height: auto;
  float: left;
  color: #242424;
  font-size: 18px;
  margin-top: 50px;
  font-family: 'poppinsmedium';
}

.about_section_contant_paragp{
  width: 100%;
  height: auto;
  float: left;
  text-align: left;
  font-size: 16px;
  color: #666;
  line-height: 28px;
  margin-top: 20px;
}
.about_section_contant_paragp p{
  text-align: left;
  font-size: 16px;
  color: #666;
}

.about_section_img{
  width: 100%;
  height: auto;
  float: left;
  text-align: center;
}
.about_section_img img{width: 80%;}

.contact_section{
  width: 100%;
  height: auto;
  float: left;
  padding: 80px 0;
}
.contact_section_head h2{
  width: 100%;
  height: auto;
  float: left;
  color: #242424;
  font-family: 'poppinsmedium';
  font-size: 40px;
}
.contact_section_head{
  width: 100%;
  height: auto;
  float: left;
  color: #242424;
  font-size: 18px;
  margin-top: 50px;
  
}
.contact_bx_phone{
  width: 100%;
  height: auto;
  float: left; 
  margin-top: 30px;
}
.contact_bx_phone_ico{
  width: 40px;
  height: 40px;
  float: left;
  background-color: #d0eeff;
  border-radius: 50px;
  text-align: center;
  line-height: 40px;
  color: #242424;
}
.contact_bx_phone span{
  width: 100%;
  height: auto;
  float: left; 
  text-align: left;
  font-size: 18px;
  color: #242424;
  margin-top: 10px;
}
.contact_bx_phone strong{
  width: 100%;
  height: auto;
  float: left; 

}
.form_sec_box{
  width: 100%;
  height: auto;
  float: left; 
  padding: 20px;
  box-shadow: 5px 0.8rem 3rem rgb(0 ,0 ,0 / 15%) !important;
  background-color: #fff;
  margin-top: 30px;
}
.form_sec_box h4{
  width: 100%;
  height: auto;
  float: left; 
  text-align: left;
  font-size: 24px;
  color: #242424;
  margin-top: 10px;
  font-family: 'poppinsmedium';
}
.form_sec_box_group{
  width: 100%;
  height: auto;
  float: left; 
  margin-top: 30px;
}
.form_sec_box_group .group{
  width: 100%;
  height: auto;
  float: left; 
  margin-bottom: 15px;
}
.fooer_link{
  text-align: right;
}
.footer_lft{
  font-size: 14px;
}
.fooer_link a{
  color: #666;
  text-decoration: none;
  font-size: 14px;
  margin-left: 10px;
}
.footer_sec{
  width: 100%;
  height: auto;
  float: left; 
  padding: 20px 0;
  background-color: #f3f3f3;
}
.row{clear: both;}
.navbar-light .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
  color: rgba(0,0,0,.9);
}
.navbar_dark{
  padding-right: 20px;
  padding-left: 20px;
  text-transform: uppercase;
  color: rgba(0,0,0,.9) !important;

}

.headershow{
  height: 4rem;
  width: 100%;
  position: relative;
  top: 0px;
  // transition: 0.3s linear;
 // transition: top 0.1s ease-in-out;
  display: flex;
  justify-content:stretch;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  z-index: 40;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  /* padding: 0 7%; */
}
.headerhidden{
  height: 4rem;
  width: 100%;
  z-index: 40;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  position: fixed;
  top: -80px;
  transition: 1.1s ease-in-out;


}

.replica_top_cnt_sec_box__2VOZh .Common_langContainer__M0jOp .Common_selectedLang__2mIhA{padding: 0 !important;}

.mob_full_sec .navbar{left: 0;padding-right: 0;}
.replica_main_header_sec__3Xx7k .navbar-expand-lg .navbar-nav .nav-link {
padding-right: 20px;
padding-left: 20px;
cursor: pointer;
padding: 10px 30px !important;
}
.replica_main_header_sec__3Xx7k .navbar-expand-lg .navbar-nav .nav-link:hover{background-color: #7266ba;color: #fff;border-radius: 40px;}
.plan_cnt_sec img{width: 100%;}
.aboutclass img{width: 100%;}

.aboutclass{
width: 100%;
height: auto;
display: inline-block;
}

@media (max-width:1190px){
.fll_wdth_row_tab{width: 100% !important;    display: inline-block;}

}
@media (max-width:991px){
  .mob_full_sec{position: absolute;right: 0;width: 100%;max-width: 100%;}
  .navbar_toggler{position: absolute; top: 14px; right: 11px;}
  .navbar-collapse{align-items: center; background-color: #fff; margin-top: 67px; box-shadow: 0px 0px 10px #00000029; padding: 20px;}
  .fooer_link{text-align: center;margin-top: 10px;}
  .footer_lft{text-align: center;}
  .main-panel{padding-top: 130px;}
  .sidebar{background: #243040;}
  .mob_full_sec .navbar{height: auto !important;padding-right: 20px;border: 0;}
  .mob_full_sec .navbar-toggler-icon{position: absolute; right: 10px; left: inherit; top: 19px;}
  .main_logo{min-height: 60px;}
  .mob_full_sec .navbar-toggler-icon:before{content: "\f0c9";font: normal normal normal 17px/1 FontAwesome; }
  .mob_full_sec .navbar li{margin-bottom: 5px;}
}
@media (max-width:768px){
  .main_logo{max-width: 200px;padding: 10px 0;}
  .main_logo img{width: 100%;}
  .fll_wdth_row_tab{width: 102% !important;}
}
@media (max-width:500px){
  .top_cnt_sec{display: inline-block;  text-align: center;}
}


//Replica
.heading{font-size:40px !important}
.subheading{font-size:18px;font-weight:500;padding-bottom:60px}
.sidebar-icon-only .main_contant_sec .topbar_section .logo_sec .dark_mode_menubartogglebtn span {
    background-color: transparent !important;
}

.sidebar-icon-only .main_contant_sec .topbar_section .logo_sec .dark_mode_menubartogglebtn span::before {
    transform: rotate(140deg);
    bottom: 20px;
    width: 25px;
}

.sidebar-icon-only .main_contant_sec .topbar_section .logo_sec .dark_mode_menubartogglebtn span::after {
    bottom: 20px;
    width: 25px;
    transform: rotate(46deg);
}
